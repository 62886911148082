import React from "react";

function Footer() {
  return (
    <footer>
      <p className="note">Math generated colors</p>
    </footer>
  );
}

export default Footer;
