import React, { useState } from "react";

function ColorGen2() {
  const [count, setCount] = useState(0);
  const [colors, setColors] = useState([]);

  if (count < 16) {
    setCount(count + 1);

    let genCol2 =
      "#" +
      ("000000" + ((Math.random() * 0xffffff) << 0).toString(16)).slice(-6);

    setColors((colors) => [...colors, genCol2]);
 
  }

  return (
    <div
      className="bg_components_box"
      style={{
        background:
          " linear-gradient( 120deg, " + colors + ") 0% 0% / 1000% 1000%",
        animation: "gradientfade 51s ease-in-out infinite",
      }}
    ></div>
  );
}

export default ColorGen2;
