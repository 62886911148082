import React from "react";

import "./App.css";
import Particles from "react-particles-js";
import Footer from "./components/footer";
import ColorGen2 from "./components/ColorGen2";

function App() {
  return (
    <div className="App">
      <ColorGen2 />
      <Footer />
      <Particles />
    </div>
  );
}

export default App;
